import React, { useContext, useEffect } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import { SocketIoContext } from 'context/SocketIoContext';

import { helpKey, supportKey } from 'constants/moviDeskKeys';

import { Index } from 'pages/Index';
import { RefreshSession } from 'pages/RefreshSession';
import { Login } from 'pages/Login';
import { Logout } from 'pages/Login/Logout';
import { ForgotPassword } from 'pages/Login/ForgotPassword';
import { ResetPassword } from 'pages/Login/ResetPassword';
import { ErrorPage } from 'pages/ErrorPage';
// import { Reports } from 'pages/Reports';
import { Audit } from 'pages/Reports/Audit';
import Occurrence from 'pages/Reports/Occurrence';
import { Pmoc } from 'pages/Reports/Pmoc';
import { Availability } from 'pages/Reports/Availability';
import { ViewGeneralPlansPage } from 'pages/Activities/GeneralPlans/ViewGeneralPlansPage';
import { NewGeneralPlanPage } from 'pages/Activities/GeneralPlans/NewGeneralPlanPage';
import { EditGeneralPlanPage } from 'pages/Activities/GeneralPlans/EditGeneralPlanPage';
import { AuthContext } from 'context/AuthContext';
import { PrivateGeneralPlansRoute, PrivateScheduleAdminRoute } from 'pages/Activities/GeneralPlans/utils';
import { Geolocalize } from 'pages/Geolocalize';
import { DashboardGeneralPage } from 'pages/Dashboard/DashboardGeral';
import { DashboardPage } from 'pages/Dashboard/DashboardEvertical';
import { ReportsPage } from 'pages/Reports/ReportsPage';
import { ClientScheduleMonitoring } from 'pages/Agendamento/AgendamentoClientes';
import { AdminScheduleMonitoring } from '../pages/Admin/AgendamentoAdmin/index';
import { Activities } from 'pages/Reports/Activities';
import { QRcodes } from 'pages/Reports/QRcodes';
import { QRcodesList } from 'pages/Reports/QRcodesList';
import { Home } from 'pages/Home';
import OccurrenceGeneratedPDF from 'pages/Reports/Occurrence/PDFOcurrence';
import jwt from 'jwt-decode';
import { PermissionContextProvider } from 'context/PermissionsContext';
import { ConectionError } from 'pages/ErrorPage/ConnectionError';
import { PermissionError } from 'pages/ErrorPage/PermissionError';
import { LandingQR } from 'pages/LandingQR';
import { PushNotification } from 'pages/Configurations/PushNotification';
import { CompaniesRegistrationPage } from 'pages/Registration/Companies';

import { CalendarReport } from 'pages/Reports/Calendar';
import { CompaniesPage } from 'pages/Companies';
const Routes = () => {
  const { setSocketIoContext } = useContext(SocketIoContext);
  const { authContext } = useContext(AuthContext);
  const { user, token } = authContext;
  const isCommonUser = user?.permissionType === `user`;
  const addMovideskScript = () => {
    const { ussupp } = jwt(token);
    const mdClient = document.createElement('script');
    const movideskScript = document.createElement('script');
    if (user.name !== null) {
      const alreadyExistMdClient = document.getElementById('mdClient');
      if (alreadyExistMdClient) {
        document.body.removeChild(alreadyExistMdClient);
      }
      const alreadyExistMovideskScript = document.getElementById('movideskScript');
      if (alreadyExistMovideskScript) {
        document.body.removeChild(alreadyExistMovideskScript);
      }
      let alreadyExistMdWidget = document.getElementById('md-app-widget');
      if (alreadyExistMdWidget) {
        document.body.removeChild(alreadyExistMdWidget);
      }
      alreadyExistMdWidget = document.getElementById('md-app-widget');
      if (alreadyExistMdWidget) {
        document.body.removeChild(alreadyExistMdWidget);
      }
      const mdKey = ussupp === 'True' ? supportKey : helpKey;
      mdClient.id = 'mdClient';
      mdClient.type = 'text/javascript';
      mdClient.innerText = `var mdChatClient = '${mdKey}';`;
      mdClient.async = false;
      document.body.appendChild(mdClient);
      movideskScript.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
      movideskScript.async = true;
      movideskScript.id = 'movideskScript';
      document.body.appendChild(movideskScript);
    }
  };

  useEffect(() => {
    if (token) {
      addMovideskScript();
    }
    // eslint-disable-next-line
  }, [token, user]);
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (location.pathname !== '/login' && location.pathname !== '/logout') {
        const script = document.createElement('script');
        script.src = '//tag.goadopt.io/injector.js?website_code=762d4206-3c1c-4c53-bb3a-eebe62d4fbea';
        script.className = 'adopt-injector';
        document.body.appendChild(script);

        const meta = document.createElement('meta');
        meta.name = 'adopt-website-id';
        meta.content = '762d4206-3c1c-4c53-bb3a-eebe62d4fbea';
        document.head.appendChild(meta);

        const script2 = document.createElement('script');
        script2.textContent = 'window.adoptHideAfterConsent=true;';
        document.head.appendChild(script2);

        return () => {
          document.body.removeChild(script);
          document.head.removeChild(meta);
          document.head.removeChild(script2);
        };
      }
    }
  }, [location.pathname]);
  return (
    <PermissionContextProvider>
      <Switch>
        {/* Erros */}
        {/* <Route path="/error/:code" component={ErrorPage} />

      {/* Refresh session */}
        <Route exact path="/refresh" component={RefreshSession} />
        {/* login */}
        <Route exact path="/login" component={Login} />
        {/* misc */}
        <Route exact path="/" component={Index} />
        {/* reports */}
        {/* <PrivateRoute
          exact
          path="/relatorios"
          Component={Reports}
        /> */}
        <PrivateRoute exact={true} path="/home" page={'home'} Component={Home} />
        <PrivateRoute exact path="/geolocalizacao" page={'geolocalizacao'} Component={Geolocalize} />

        <PrivateRoute exact path="/dashboard" page={'dashboard'} Component={DashboardPage} />
        <PrivateRoute exact path="/dashboard/:dateFrom" page={'dashboard'} Component={DashboardPage} />
        <PrivateRoute exact path="/dashboard/:dateFrom/:dateTo" page={'dashboard'} Component={DashboardPage} />

        <PrivateRoute exact path="/dashboard-geral" page={'dashboard'} Component={DashboardGeneralPage} />
        <PrivateRoute exact path="/dashboard-geral/:dateFrom" page={'dashboard'} Component={DashboardGeneralPage} />
        <PrivateRoute
          exact
          path="/dashboard-geral/:dateFrom/:dateTo"
          page={'dashboard'}
          Component={DashboardGeneralPage}
        />

        <PrivateRoute exact path="/relatorios" page={'relatorios'} Component={ReportsPage} />
        <PrivateRoute exact path="/relatorios/auditoria" page={'relatorios'} Component={Audit} />
        <PrivateRoute
          exact
          path="/relatorios/ocorrencia"
          page={'relatorios'}
          Component={Occurrence}
          onLeave={() =>
            setSocketIoContext({
              id: null,
              isSocketPending: false,
              isSocketActive: false,
            })
          }
        />
        <PrivateRoute
          exact
          path="/relatórios/relatorios-gerados-ocorrencia-pdf"
          page={'relatorios'}
          Component={OccurrenceGeneratedPDF}
        />
        <PrivateRoute exact path="/configuracoes/notificacoes-push" page={'push'} Component={PushNotification} />
        <PrivateRoute exact path="/relatorios/disponibilidade" page={'relatorios'} Component={Availability} />
        <PrivateRoute exact path="/relatorios/pmoc" page={'relatorios'} Component={Pmoc} />
        <PrivateRoute exact path="/relatorios/atividades" page={'relatorios'} Component={Activities} />
        <PrivateRoute exact path="/relatorios/qr-codes" page={'relatorios'} Component={QRcodes} />
        <PrivateRoute path="/relatorios/qr-codes-impressao" page={'relatorios'} Component={QRcodesList} />
        <PrivateRoute path="/cadastros/empresas" page={'cadastros'} Component={CompaniesRegistrationPage} />
        <PrivateRoute path="/relatorios/calendario-atividades" page={'relatorios'} Component={CalendarReport} />
        <PrivateGeneralPlansRoute
          isDisabled={isCommonUser}
          exact
          path="/atividades/planos-gerais"
          page={'planosGerais'}
          Component={ViewGeneralPlansPage}
        />
        <PrivateGeneralPlansRoute
          isDisabled={isCommonUser}
          exact
          page={'planosGerais'}
          path="/atividades/planos-gerais/novo"
          Component={NewGeneralPlanPage}
        />
        <PrivateGeneralPlansRoute
          isDisabled={isCommonUser}
          exact
          page={'planosGerais'}
          path="/atividades/planos-gerais/:id"
          Component={EditGeneralPlanPage}
        />
        <Route path="/monitoria-de-agendamento-cliente" component={ClientScheduleMonitoring} />
        <PrivateScheduleAdminRoute
          isDisabled={isCommonUser}
          exact
          path="/monitoria-de-agendamento-admin"
          component={AdminScheduleMonitoring}
        />
        <Route path="/conta/recuperar" component={ForgotPassword} />
        <Route path="/logout" component={Logout} />
        <Route exact path="/conta/redefinir-senha/:token" component={ResetPassword} />
        <Route exact path="/conection-error" component={ConectionError} />
        <Route exact path="/permission-error" component={PermissionError} />
        <Route exact path="/qr/:code" component={LandingQR} />
        <Route exact path="/clientes/:subdominio" component={CompaniesPage} />
        {/* 404 */}
        <Route path="*">
          <ErrorPage errorCode="404" />
        </Route>
      </Switch>
    </PermissionContextProvider>
  );
};

export default withRouter(Routes);
